<h2>Eventos</h2>
<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">Tipo</th>
        <th scope="col">Nome</th>     
        <th scope="col">Descrição</th>
        <th scope="col">Data e Hora</th>
        <th scope="col">Ação</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let event of events">
        <td class="align-middle">{{event.type_event_type.type}}</td>
        <td class="align-middle">{{event.title}}</td>
        <td class="align-middle">{{event.team_b_team.name}}</td>
        <td class="align-middle">{{event.start_date | date:'MMM d, y H:mm'}}</td>
        <td class="align-middle">
          <a routerLink="/admin/panel/eventos/{{event.uuid}}/relatorio" target="_blank" title="Ver Relatório de Assistência"><i class="ti-bar-chart"></i></a>
          <span *ngIf="event.canInvite">
            &nbsp;
            <i class="ti-email" data-target="#Convite" data-toggle="modal" (click)="selectEvent(event)" title="Gerar Convite"></i>
          </span>      
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!--MODAL-->
<div class="modal fade" id="Convite" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content rounded-0 border-0 p-4">
            <div class="modal-header border-0">
                <h3 *ngIf="selectedEvent != null">Gerar convite - {{selectedEvent.team_b_team.name}}</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-xl-12">
                    <form action="#" class="row">
                        <div class="col-12">
                            <input type="text" class="form-control mb-3" [(ngModel)]="name" id="loginName" name="name" placeholder="Nome no convite" required>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary submit" (click)="generateInvite()" [disabled]="pendingAction">Gerar</button>
                        </div>
                    </form>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>