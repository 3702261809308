import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt-PT';
registerLocaleData(localePt);

import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { HeaderComponent } from './util/header/header.component';
import { SliderComponent } from './slider/slider.component';
import { BannerComponent } from './banner/banner.component';
import { MissaoComponent } from './missao/missao.component';
import { CtaSocioComponent } from './cta-socio/cta-socio.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { FooterComponent } from './util/footer/footer.component';
import { NoticiaSingleComponent } from './noticia-single/noticia-single.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { OrgaosComponent } from './orgaos/orgaos.component';
import { HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from './util/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './util/login/login.component';
import { AdminIndexComponent } from './admin-index/admin-index.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminDestaquesComponent } from './admin-destaques/admin-destaques.component';
import { AdminNoticiasComponent } from './admin-noticias/admin-noticias.component';
import { VideoLandingComponent } from './video-landing/video-landing.component';
import { AdminNoticiasFactoryComponent } from './admin-noticias-factory/admin-noticias-factory.component';
import { FormsModule } from '@angular/forms';
import { SobreNosComponent } from './sobre-nos/sobre-nos.component';
import { ContactsComponent } from './contacts/contacts.component';
import { NotifierModule } from 'angular-notifier';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginSocioComponent } from './util/login-socio/login-socio.component';
import { PayQuotaComponent } from './socios/pay-quota/pay-quota.component';
import { DonationComponent } from './donation/donation.component';
import { BilhetesComponent } from './bilhetes/bilhetes.component';
import { NovoSocioComponent } from './novo-socio/novo-socio.component';
import { ProgressbarComponent } from './util/progressbar/progressbar.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { AdminPanelSociosComponent } from './admin/admin-panel-socios/admin-panel-socios.component';
import { AdminPanelLeadsComponent } from './admin/admin-panel-leads/admin-panel-leads.component';
import { AdminPanelDonativosComponent } from './admin/admin-panel-donativos/admin-panel-donativos.component';
import { AdminPanelSociosSingleComponent } from './admin/admin-panel-socios-single/admin-panel-socios-single.component';
import { SimNaoPipe } from './pipes/sim-nao.pipe';
import { PagaNaoPagaPipe } from './pipes/paga-nao-paga.pipe';
import { AdminPanelBilhetesComponent } from './admin/admin-panel-bilhetes/admin-panel-bilhetes.component';
import { BilheteEpocaComponent } from './bilhete-epoca/bilhete-epoca.component';
import { AdminPanelJogosComponent } from './admin/admin-panel-jogos/admin-panel-jogos.component';
import { AdminPanelEventosComponent } from './admin/admin-panel-eventos/admin-panel-eventos.component';
import { MostraTreatoComponent } from './atividades/mostra-treato/mostra-treato.component';
import { CartaoComponent } from './socios/cartao/cartao.component';
import { ValidateComponent } from './validate/validate.component';
import { PartnerSearchComponent } from './partner/partner-search/partner-search.component';
import { AdminPanelEventosRelatorioComponent } from './admin/admin-panel-eventos-relatorio/admin-panel-eventos-relatorio.component';
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    SliderComponent,
    BannerComponent,
    MissaoComponent,
    CtaSocioComponent,
    NoticiasComponent,
    FooterComponent,
    NoticiaSingleComponent,
    DocumentosComponent,
    OrgaosComponent,
    LoaderComponent,
    LoginComponent,
    AdminIndexComponent,
    AdminDashboardComponent,
    AdminDestaquesComponent,
    AdminNoticiasComponent,
    VideoLandingComponent,
    AdminNoticiasFactoryComponent,
    SobreNosComponent,
    ContactsComponent,
    LoginSocioComponent,
    PayQuotaComponent,
    DonationComponent,
    BilhetesComponent,
    NovoSocioComponent,
    ProgressbarComponent,
    AdminPanelComponent,
    AdminPanelSociosComponent,
    AdminPanelLeadsComponent,
    AdminPanelDonativosComponent,
    AdminPanelSociosSingleComponent,
    SimNaoPipe,
    PagaNaoPagaPipe,
    AdminPanelBilhetesComponent,
    BilheteEpocaComponent,
    AdminPanelJogosComponent,
    AdminPanelEventosComponent,
    MostraTreatoComponent,
    CartaoComponent,
    ValidateComponent,
    PartnerSearchComponent,
    AdminPanelEventosRelatorioComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    QuillModule.forRoot(),
    FormsModule,
    JwtModule.forRoot({
      config:{
        tokenGetter: () => {
          return localStorage.getItem("ascvdpt_portal");
        }
      }
    }),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12,
        },
        vertical: {
          position: 'top',
          distance: 75,
          gap: 10,
        },
      },
    }),
  ], 
  providers: [
    {provide:LOCALE_ID, useValue:'pt-PT'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
