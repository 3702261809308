import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-admin-panel-eventos-relatorio',
  templateUrl: './admin-panel-eventos-relatorio.component.html',
  styleUrls: ['./admin-panel-eventos-relatorio.component.css']
})
export class AdminPanelEventosRelatorioComponent implements OnInit {

  isLoading : boolean = true;

  ticketLst : any;
  eventInfo : any;

  soldTickets : number;
  usedTickets : number = 0;

  ticketByStandMap : any;
  ticketByPayMehtod : any;

  mbwTokenLst : any = [];

  constructor(private adminService : AdminServiceService, private route : ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe( (params) => {

      this.adminService.getEventReport(params.eventId).subscribe((res:any)=>{

        if(res.ticket){
          this.ticketLst = res.ticket;
          this.eventInfo = res.ticket[0]?.event_event;
          this.soldTickets = res.ticket.length;
          this.ticketByStandMap = new Map();
          this.ticketByPayMehtod = new Map();

          this.ticketLst.forEach((ticket:any)=>{
            if(ticket.is_used){
              this.usedTickets++;
            }

            if(ticket.pay_method == 'MBW'){
              this.mbwTokenLst.push(ticket.token);
            }
            
            //Stand Map 
            if(this.ticketByStandMap.has(ticket.seat_seat.stand)){
              this.ticketByStandMap.get(ticket.seat_seat.stand)['tickets'].push(ticket);
              if(ticket.is_used){
                this.ticketByStandMap.get(ticket.seat_seat.stand)['used']++;
              }
            }else{
              this.ticketByStandMap.set(ticket.seat_seat.stand, {used:ticket.is_used?1:0, tickets:[ticket]});
            }

            //MethodMap
            if(this.ticketByPayMehtod.has(ticket.pay_method)){
              this.ticketByPayMehtod.get(ticket.pay_method)['tickets'].push(ticket);
              if(ticket.is_used){
                this.ticketByPayMehtod.get(ticket.pay_method)['used']++;
              }
            }else{
              this.ticketByPayMehtod.set(ticket.pay_method, {used:ticket.is_used?1:0, tickets:[ticket]});
            }

          });

        }else if(res.event){
          this.eventInfo = res.event;
        }

        this.isLoading = false;

      });

    });
    

  }

}
